export const servicesPeriodChoices = [
  { name: 'Раз в год', id: 'year' },
  { name: '2 раза в год', id: 'twoYear' },
  { name: 'Раз в квартал', id: 'quarter' },
  { name: 'Раз в месяц', id: 'month' },
  { name: '2 раза в месяц', id: 'twoMonth' },
  { name: 'Раз в неделю', id: 'week' },
  { name: 'Единовременная', id: 'single' },
  { name: 'По заявкам', id: 'request' },
];
