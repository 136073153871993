import { FC, PropsWithChildren, useEffect } from 'react';
import { CreateContextProvider, useCreateController, useNotify, useRefresh } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDialog } from 'src/hooks/useDialog';

export const CreateDialog: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    navigate(location.pathname.replace('/create', ''));
  };

  const handleError = (error: unknown) => {
    console.error(error);
    notify('При добавлении произошла ошибка', { type: 'warning' });
  };

  const context = useCreateController({
    mutationOptions: { onError: handleError, onMutate: closeDialog, onSuccess: refresh },
    redirect: 'list',
  });

  useEffect(() => {
    if (location.pathname.endsWith('/create')) {
      openDialog(<CreateContextProvider value={context}>{children}</CreateContextProvider>, {
        onClose: handleClose,
      });
    }
  }, [location]);

  return null;
};
