import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'src/App';

const container = document.getElementById('root');

if (!container) {
  throw new Error();
}

const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
