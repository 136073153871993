import { Create } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { AddressForm } from 'src/Pages/Addresses/AddressForm';

export const AddressCreate = () => {
  const {
    state: { redirectUrl },
  } = useLocation() as { state: { contragentId?: number; redirectUrl?: string } };
  return (
    <Create redirect={redirectUrl}>
      <AddressForm />
    </Create>
  );
};
