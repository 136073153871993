import { Box, Typography } from '@mui/material';
import big from 'big.js';
import { Pagination, useListContext } from 'react-admin';

export const ContractPagination = () => {
  const { data } = useListContext();

  if (!data) {
    return null;
  }

  const contractSum = data
    ?.reduce((acc, contract) => big(acc).plus(contract.contractSum || 0), big(0))
    .toFixed(2);

  return (
    <>
      <Box pr={3} py={2}>
        <Typography textAlign="right" variant="body2">
          Контрактов на сумму: {contractSum}
        </Typography>
      </Box>
      <Pagination />
    </>
  );
};
