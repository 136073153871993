import {
  AutocompleteInput,
  Datagrid,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { EditInDialogButton } from 'src/Components/EditInDialogButton';
import { districtChoices } from 'src/constants/districtChoices';
import { ContractPagination } from 'src/Pages/Contracts/Components/ContractPagination';
import { ContractsForm } from 'src/Pages/Contracts/Components/ContractsForm';

const contractsFilters = [
  <TextInput label="Номер" source="name" autoComplete="off" alwaysOn />,
  <TextInput label="Номер контракта" source="contractNumber" autoComplete="off" alwaysOn />,
  <TextInput label="ИНН" source="contragent.inn" autoComplete="off" alwaysOn />,
  <ReferenceInput label="Компания" reference="companies" source="company.id" alwaysOn>
    <AutocompleteInput
      optionText="name"
      source="company"
      label="Компания"
      TextFieldProps={{ fullWidth: true, variant: 'outlined' }}
      size="small"
    />
  </ReferenceInput>,
  <AutocompleteInput
    source="serviceItems.address.district"
    variant="outlined"
    label="Район"
    TextFieldProps={{ fullWidth: true, variant: 'outlined', sx: { width: 300 } }}
    size="small"
    choices={districtChoices}
    alwaysOn
  />,
  <ReferenceInput
    source="serviceItems.serviceCategory.id"
    reference="service-categories"
    alwaysOn
    perPage={100}
  >
    <SelectInput optionText="name" label="Категория" variant="outlined" sx={{ width: 200 }} />
  </ReferenceInput>,
];

export const ContractsList = () => (
  <List
    title="Договоры"
    filters={contractsFilters}
    actions={false}
    queryOptions={{ meta: { populate: ['contragent', 'company'] } }}
    pagination={<ContractPagination />}
  >
    <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
      <TextField source="name" label="Номер" />
      <TextField source="contractNumber" label="Номер контракта" />
      <TextField source="contragent.shortName" label="Контрагент" />
      <TextField source="contragent.inn" label="ИНН" />
      <TextField source="contractSum" emptyText="—" label="Сумма" />
      <EditInDialogButton populate={['serviceCategory']}>
        <ContractsForm />
      </EditInDialogButton>
    </Datagrid>
  </List>
);
