import { FC, PropsWithChildren } from 'react';
import {
  EditContextProvider,
  useEditController,
  useGetRecordId,
  useNotify,
  useRefresh,
} from 'react-admin';
import { EditButton } from 'src/Components/EditButton';
import { useDialog } from 'src/hooks/useDialog';

interface Props {
  populate?: string[] | string;
}

export const EditInDialogButton: FC<PropsWithChildren<Props>> = ({ children, populate }) => {
  const id = useGetRecordId();
  const { openDialog, closeDialog } = useDialog();
  const refresh = useRefresh();
  const notify = useNotify();

  const onMutate = () => {
    closeDialog();
    refresh();
  };

  const onError = (error: unknown) => {
    console.error(error);
    notify('При редактировании произошла ошибка', { type: 'warning' });
  };

  const editContext = useEditController({
    id,
    redirect: false,
    // ...{populate ? { queryOptio meta: { populate } } : {}},
    queryOptions: { meta: { populate } },
    mutationOptions: {
      onMutate,
      onError,
    },
  });

  const handleEditClick = () => {
    openDialog(<EditContextProvider value={editContext}>{children}</EditContextProvider>);
  };

  return <EditButton onClick={handleEditClick} />;
};
