import { DateField, Tab, TabbedShowLayout, TextField, UrlField } from 'react-admin';
import { ServiceTab } from 'src/Pages/Contracts/Components/ServiceTab';

export const ShowTabs = () => {
  return (
    <TabbedShowLayout>
      <Tab label="Услуги">
        <ServiceTab />
      </Tab>
      <Tab label="Данные договора">
        <TextField source="company.name" label="Компания" />
        <TextField source="name" label="Номер" />
        <TextField source="contractNumber" label="Номер контракта" emptyText="—" />
        <UrlField source="contractUrl" label="Ссылка на договор" target="_blank" emptyText="—" />
        <TextField source="contractSum" label="Сумма договора" emptyText="—" />
        <DateField
          source="startContractDate"
          label="Начало договора"
          emptyText="—"
          locales="ru-RU"
        />
        <DateField
          source="endContractDate"
          label="Окончание договора"
          emptyText="—"
          locales="ru-RU"
        />
      </Tab>
    </TabbedShowLayout>
  );
};
