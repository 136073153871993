import {
  maxLength,
  minLength,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { SectionTitle } from 'src/Components/SectionTitle';
import { contragentTypesChoices } from 'src/constants/contragentTypesChoices';

export const ContragentForm = () => (
  <SimpleForm
    toolbar={
      <Toolbar>
        <SaveButton />
      </Toolbar>
    }
    data-test-id="contragent-form"
  >
    <SectionTitle label="Данные контрагента" />
    <SelectInput
      source="contragentType"
      choices={contragentTypesChoices}
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Вид контрагента"
      defaultValue="legalEntity"
      fullWidth
      validate={required()}
    />
    <TextInput
      source="inn"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="ИНН"
      autoComplete="off"
      fullWidth
      validate={[required(), minLength(10), maxLength(12)]}
    />
    <TextInput
      source="shortName"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Короткое название"
      autoComplete="off"
      validate={required()}
      fullWidth
    />
    <TextInput
      source="name"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Название компании"
      autoComplete="off"
      fullWidth
      validate={required()}
    />
    <TextInput
      source="kpp"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="КПП"
      autoComplete="off"
      fullWidth
    />
    <TextInput
      source="ogrn"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="ОГРН"
      autoComplete="off"
      fullWidth
    />
    <TextInput
      source="address"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Юридический адрес"
      autoComplete="off"
      fullWidth
    />
    <TextInput
      source="actualAddress"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Фактический Адрес"
      autoComplete="off"
      fullWidth
    />
    <TextInput
      source="contactName"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Контактное лицо"
      autoComplete="off"
      fullWidth
    />
    <TextInput
      source="tel"
      type="tel"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Телефон"
      autoComplete="off"
      fullWidth
    />
    <TextInput
      source="email"
      type="email"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Электронная почта"
      autoComplete="off"
      fullWidth
    />

    <SectionTitle label="Данные банка" />
    <TextInput
      source="bik"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="БИК"
      autoComplete="off"
      fullWidth
      validate={[minLength(9), maxLength(9)]}
    />
    <TextInput
      source="bankName"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Название банка"
      autoComplete="off"
      fullWidth
    />
    <TextInput
      source="paymentAccount"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Расчётный счёт"
      autoComplete="off"
      fullWidth
      validate={[minLength(20), maxLength(20)]}
    />
    <TextInput
      source="correspondentAccount"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Корреспондентский счёт"
      autoComplete="off"
      fullWidth
      validate={[minLength(20), maxLength(20)]}
    />
  </SimpleForm>
);
