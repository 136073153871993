import { servicesPeriodChoices } from 'src/constants/servicesPeriodChoices';

export const findServicePeriodChoice = (value?: string): string => {
  if (value === undefined) {
    return '-';
  }
  const result = servicesPeriodChoices.find((period) => period.id === value);

  return result?.name || '—';
};
