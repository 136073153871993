import { Show, SimpleShowLayout, TextField } from 'react-admin';

export const AddressShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" label="Название" />
      <TextField source="address" label="Адрес" />
    </SimpleShowLayout>
  </Show>
);
