import { FC } from 'react';
import { AutocompleteInput, required, SimpleForm, TextInput } from 'react-admin';
import { SectionTitle } from 'src/Components/SectionTitle';
import { districtChoices } from 'src/constants/districtChoices';

export const AddressForm: FC = () => (
  <SimpleForm>
    <SectionTitle label="Адрес" />
    <TextInput
      source="name"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Название"
      autoComplete="off"
      fullWidth
      validate={required()}
    />
    <TextInput
      source="address"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Адрес"
      autoComplete="off"
      fullWidth
      validate={required()}
    />
    <AutocompleteInput
      source="district"
      variant="outlined"
      label="Район"
      size="small"
      sx={{ width: 400 }}
      fullWidth
      choices={districtChoices}
    />
    <TextInput
      source="addressTel"
      type="tel"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Телефон"
      autoComplete="off"
      fullWidth
    />
    <TextInput
      source="contactName"
      type="tel"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Контактное лицо"
      autoComplete="off"
      fullWidth
    />
    <TextInput
      source="addressInformation"
      multiline
      rows={3}
      type="tel"
      variant="outlined"
      size="small"
      sx={{ width: 400 }}
      label="Дополнительная информация"
      autoComplete="off"
      fullWidth
    />
  </SimpleForm>
);
