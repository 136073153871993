import { Cookies } from 'src/utils/Cookies';

const authProvider = {
  login: ({ username, password }) => {
    const identifier = username; // strapi expects 'identifier' and not 'username'
    const request = new Request(
      `${process.env.REACT_APP_API_URL || 'http://localhost:1337/api'}/auth/local`,
      {
        method: 'POST',
        body: JSON.stringify({ identifier, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      },
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        Cookies.setCookie('token', response.jwt, 30);
        Cookies.setCookie('role', response.user.role?.name, 30);
      });
  },

  logout: () => {
    Cookies.deleteCookie('token');
    Cookies.deleteCookie('role');
    return Promise.resolve();
  },

  checkAuth: () => {
    return Cookies.getCookie('token') ? Promise.resolve() : Promise.reject();
  },

  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      Cookies.deleteCookie('token');
      Cookies.deleteCookie('role');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    const role = Cookies.getCookie('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export { authProvider };
