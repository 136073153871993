import { Box } from '@mui/material';
import {
  CreateContextProvider,
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  TextField,
  useCreateController,
  useRecordContext,
} from 'react-admin';
import { CreateInDialogButton } from 'src/Components/CreateInDialogButton';
import { EditInDialogButton } from 'src/Components/EditInDialogButton';
import { ContractsForm } from 'src/Pages/Contracts/Components/ContractsForm';

export const ContractTab = () => {
  const contragentData = useRecordContext();
  const createContractContext = useCreateController({
    resource: 'contracts',
    record: { contragent: { id: contragentData.id } },
  });

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <CreateContextProvider value={createContractContext}>
          <CreateInDialogButton label="Добавить договор">
            <ContractsForm />
          </CreateInDialogButton>
        </CreateContextProvider>
      </Box>
      <ReferenceManyField
        reference="contracts"
        target="contragent"
        label={false}
        pagination={<Pagination />}
      >
        <Datagrid
          rowClick="show"
          size="medium"
          bulkActionButtons={false}
          data-test-id="contragent-contracts"
        >
          <TextField source="name" label="Название" />
          <DateField
            source="startContractDate"
            label="Начало договора"
            emptyText="—"
            locales="ru-RU"
          />
          <DateField
            source="endContractDate"
            label="Конец договора"
            emptyText="—"
            locales="ru-RU"
          />
          <EditInDialogButton populate={['serviceCategory']}>
            <ContractsForm />
          </EditInDialogButton>
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
