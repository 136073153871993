import {
  AutocompleteInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetList,
} from 'react-admin';
import { SectionTitle } from 'src/Components/SectionTitle';
import { step } from 'src/validators/step';

export const ContractsForm = () => {
  const { data: companies = [], isLoading } = useGetList('companies');

  if (isLoading) {
    return <>...</>;
  }

  return (
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
      data-test-id="contract-form"
    >
      <SectionTitle label="Договор" />
      <AutocompleteInput
        optionText="name"
        source="company"
        choices={companies}
        label="Компания"
        TextFieldProps={{ fullWidth: true, variant: 'outlined', sx: { width: 400 } }}
        defaultValue={companies[0]?.id}
        size="small"
        validate={required()}
      />
      <TextInput
        source="name"
        variant="outlined"
        size="small"
        sx={{ width: 400 }}
        label="Номер"
        autoComplete="off"
        fullWidth
        validate={required()}
      />

      <TextInput
        source="contractNumber"
        variant="outlined"
        size="small"
        sx={{ width: 400 }}
        label="Номер контракта"
        autoComplete="off"
        fullWidth
        validate={required()}
      />

      <TextInput
        source="contractUrl"
        variant="outlined"
        size="small"
        sx={{ width: 400 }}
        label="Ссылка на контракт"
        autoComplete="off"
        fullWidth
      />
      <ReferenceInput reference="contract-service-categories" source="serviceCategory.id">
        <AutocompleteInput
          optionText="name"
          label="Категория"
          variant="outlined"
          sx={{ width: 300 }}
          size="small"
          filterToQuery={(q) => ({ name: q })}
          fullWidth
        />
      </ReferenceInput>

      <NumberInput
        source="contractSum"
        variant="outlined"
        label="Сумма договора"
        size="small"
        defaultValue={null}
        validate={step(0.01)}
      />

      <DateInput
        source="startContractDate"
        variant="outlined"
        size="small"
        label="Начало договора"
        validate={required()}
      />
      <DateInput
        source="endContractDate"
        variant="outlined"
        size="small"
        label="Конец договора"
        validate={required()}
      />
    </SimpleForm>
  );
};
