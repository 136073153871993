import { Button } from '@mui/material';
import { useRecordContext } from 'ra-core';
import { FC, PropsWithChildren } from 'react';
import {
  CreateContextProvider,
  useCreateContext,
  useCreateController,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useDialog } from 'src/hooks/useDialog';

interface Props {
  label?: string;
  resource?: string;
}

export const CreateInDialogButton: FC<PropsWithChildren<Props>> = ({
  label = 'создать',
  resource,
  children,
}) => {
  const { openDialog, closeDialog } = useDialog();
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  const { resource: currentResource } = useCreateContext();

  const handleError = (error: unknown) => {
    console.error(error);
    notify('При добавлении произошла ошибка', { type: 'warning' });
  };

  const context = useCreateController({
    mutationOptions: { onError: handleError, onMutate: closeDialog, onSuccess: refresh },
    redirect: false,
    resource: resource || currentResource,
    record,
  });

  const handleCreateClick = () =>
    openDialog(<CreateContextProvider value={context}>{children}</CreateContextProvider>);

  return (
    <Button onClick={handleCreateClick} size="small" variant="contained">
      {label}
    </Button>
  );
};
