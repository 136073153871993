export const districtChoices = [
  { name: 'Адмиралтейский район', id: 'Адмиралтейский район' },
  { name: 'Василеостровский район', id: 'Василеостровский район' },
  { name: 'Выборгский район', id: 'Выборгский район' },
  { name: 'Калининский район', id: 'Калининский район' },
  { name: 'Кировский район', id: 'Кировский район' },
  { name: 'Колпинский район', id: 'Колпинский район' },
  { name: 'Красногвардейский район', id: 'Красногвардейский район' },
  { name: 'Красносельский район', id: 'Красносельский район' },
  { name: 'Кронштадтский район', id: 'Кронштадтский район' },
  { name: 'Курортный район', id: 'Курортный район' },
  { name: 'Московский район', id: 'Московский район' },
  { name: 'Невский район', id: 'Невский район' },
  { name: 'Петроградский район', id: 'Петроградский район' },
  { name: 'Петродворцовый район', id: 'Петродворцовый район' },
  { name: 'Приморский район', id: 'Приморский район' },
  { name: 'Пушкинский район', id: 'Пушкинский район' },
  { name: 'Фрунзенский район', id: 'Фрунзенский район' },
  { name: 'Центральный район', id: 'Центральный район' },
  { name: 'Бокситогорский муниципальный район', id: 'Бокситогорский муниципальный район' },
  { name: 'Волосовский муниципальный район', id: 'Волосовский муниципальный район' },
  { name: 'Волховский муниципальный район', id: 'Волховский муниципальный район' },
  { name: 'Всеволожский муниципальный район', id: 'Всеволожский муниципальный район' },
  { name: 'Выборгский муниципальный район', id: 'Выборгский муниципальный район' },
  { name: 'Гатчинский муниципальный район', id: 'Гатчинский муниципальный район' },
  { name: 'Кингисеппский муниципальный район', id: 'Кингисеппский муниципальный район' },
  { name: 'Киришский муниципальный район', id: 'Киришский муниципальный район' },
  { name: 'Кировский муниципальный район', id: 'Кировский муниципальный район' },
  { name: 'Лодейнопольский муниципальный район', id: 'Лодейнопольский муниципальный район' },
  { name: 'Ломоносовский муниципальный район', id: 'Ломоносовский муниципальный район' },
  { name: 'Лужский муниципальный район', id: 'Лужский муниципальный район' },
  { name: 'Подпорожский муниципальный район', id: 'Подпорожский муниципальный район' },
  { name: 'Приозерский муниципальный район', id: 'Приозерский муниципальный район' },
  { name: 'Сланцевский муниципальный район', id: 'Сланцевский муниципальный район' },
  { name: 'Тихвинский муниципальный район', id: 'Тихвинский муниципальный район' },
  { name: 'Тосненский муниципальный район', id: 'Тосненский муниципальный район' },
  { name: 'Сосновоборгский городской округ', id: 'Сосновоборгский городской округ' },
  { name: 'Новгородская область', id: 'Новгородская область' },
  { name: 'Псковская область', id: 'Псковская область' },
];
