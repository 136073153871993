import { Dialog, DialogTitle } from '@mui/material';
import { FC } from 'react';
import { useCreateController, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { ServiceItemDto } from 'src/dto/ServiceItemDto';
import { ServiceItemForm } from 'src/Pages/ServiceItems/ServiceItemForm';

interface CreateServiceItemDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
export const CreateServiceItemDialog: FC<CreateServiceItemDialogProps> = ({ isOpen, onClose }) => {
  const { save, record } = useCreateController<ServiceItemDto>({
    resource: 'service-items',
    record: {},
    redirect: false,
  });

  const { contract, address, contragent, id } = useRecordContext();

  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = (data: FieldValues) => {
    save && save({ ...data, service: id, contract, address, contragent }, { onError, onSuccess });
  };

  const onError = () => {
    notify('При сохранении произошла ошибка', { type: 'warning' });
  };

  const onSuccess = () => {
    onClose();
    notify('Обработка добавлена');
    refresh();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Добавить обработку</DialogTitle>
      <ServiceItemForm onSubmit={handleSubmit} record={record} />
    </Dialog>
  );
};
