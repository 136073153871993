import { Box } from '@mui/material';
import { FC } from 'react';
import {
  CreateContextProvider,
  Datagrid,
  ReferenceManyField,
  TextField,
  useCreateController,
  useRecordContext,
} from 'react-admin';
import { CreateInDialogButton } from 'src/Components/CreateInDialogButton';
import { EditInDialogButton } from 'src/Components/EditInDialogButton';
import { AddressForm } from 'src/Pages/Addresses/AddressForm';

export const AddressTab: FC = () => {
  const contragentData = useRecordContext();
  const addressContext = useCreateController({
    resource: 'addresses',
    record: { contragent: { id: contragentData.id } },
  });

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <CreateContextProvider value={addressContext}>
          <CreateInDialogButton label="Добавить адрес">
            <AddressForm />
          </CreateInDialogButton>
        </CreateContextProvider>
      </Box>
      <ReferenceManyField reference="addresses" target="contragent" label={false}>
        <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
          <TextField source="name" label="Название" />
          <TextField source="address" label="Адрес" />
          <EditInDialogButton>
            <AddressForm />
          </EditInDialogButton>
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};
