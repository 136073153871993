interface Filter {
  [key: string]: string | number | Filter;
}

export const filterBuilder = (filter: Filter = {}): Filter => {
  return Object.entries(filter).reduce(
    (acc: Filter, [key, value]: [string, string | number | Filter]) => {
      if (key === 'q') {
        return { ...acc, _q: value };
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        return { ...acc, [key]: filterBuilder(value) };
      }

      if (key === 'id') {
        return { ...acc, [key]: { $eq: value } };
      }

      return { ...acc, [key]: { $containsi: value } };
    },
    {},
  );
};
