import { Edit } from 'react-admin';
import { ServiceItemForm } from 'src/Pages/ServiceItems/ServiceItemForm';

export const ServiceItemEdit = () => {
  return (
    <Edit>
      <ServiceItemForm />
    </Edit>
  );
};
