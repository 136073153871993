import { Show } from 'react-admin';
import { ShowTabs } from 'src/Pages/Contracts/Components/ShowTabs';

export const ContractShow = () => {
  return (
    <Show
      actions={false}
      queryOptions={{ meta: { populate: ['contragent', 'company', 'services'] } }}
    >
      <ShowTabs />
    </Show>
  );
};
