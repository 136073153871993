import { createContext, ReactNode } from 'react';

export interface OpenDialogOptions {
  onClose?: () => void;
}

interface Context {
  openDialog: (content: ReactNode, options?: OpenDialogOptions) => void;
  closeDialog: () => void;
}
const defaultContext: Context = {
  openDialog: () => void 0,
  closeDialog: () => void 0,
};

export const DialogContext = createContext<Context>(defaultContext);
