import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import { Admin, Resource } from 'react-admin';
import { BrowserRouter } from 'react-router-dom';
import { contracts } from 'src//Pages/Contracts/contracts';
import { Layout } from 'src/Components/Layout';
import { AddressCreate } from 'src/Pages/Addresses/AddressCreate';
import { AddressEdit } from 'src/Pages/Addresses/AddressEdit';
import { AddressShow } from 'src/Pages/Addresses/AddressShow';
import { contragents } from 'src/Pages/Contragents/contragents';
import { ServiceItemCreate } from 'src/Pages/ServiceItems/ServiceItemCreate';
import { ServiceItemEdit } from 'src/Pages/ServiceItems/ServiceItemEdit';
import { ServiceItemShow } from 'src/Pages/ServiceItems/ServiceItemShow';
import { ServiceShow } from 'src/Pages/Services/ServiceShow';
import { authProvider } from 'src/utils/authProvider';
import { httpClient } from 'src/utils/httpClient';
import { strapiProviderOld } from 'src/utils/strapiProviderOld';
import { theme } from 'src/utils/theme';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru', { allowMissing: true });
const apiUrl = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}`
  : 'http://localhost:1337/api';

export const App = () => (
  <BrowserRouter>
    <Admin
      dataProvider={strapiProviderOld(apiUrl, httpClient)}
      title="Станция Дезинфекции"
      authProvider={authProvider}
      theme={theme}
      i18nProvider={i18nProvider}
      layout={Layout}
      requireAuth
    >
      <Resource
        name="contragents"
        options={{ label: 'Контрагенты' }}
        icon={BusinessCenterIcon}
        {...contragents}
      />
      <Resource
        name="contracts"
        options={{ label: 'Договоры' }}
        icon={FilePresentIcon}
        {...contracts}
      />
      <Resource
        name="addresses"
        options={{ label: 'Адреса' }}
        show={AddressShow}
        create={AddressCreate}
        edit={AddressEdit}
        icon={BusinessIcon}
      />
      <Resource
        name="services"
        options={{ label: 'Услуги' }}
        show={ServiceShow}
        icon={CleaningServicesIcon}
      />
      <Resource
        name="service-items"
        options={{ label: 'Обработки' }}
        show={ServiceItemShow}
        create={ServiceItemCreate}
        edit={ServiceItemEdit}
        icon={CleaningServicesIcon}
      />
    </Admin>
  </BrowserRouter>
);
