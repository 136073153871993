import { FC } from 'react';
import { Create } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { CreateServiceItemDto } from 'src/dto/CreateServiceItemDto';
import { ServiceItemForm } from 'src/Pages/ServiceItems/ServiceItemForm';

export const ServiceItemCreate: FC = () => {
  const {
    state: { redirectUrl, service },
  } = useLocation() as { state: { service: number; redirectUrl: string } };
  const transform = (data: CreateServiceItemDto) => ({
    service,
    ...data,
  });

  return (
    <Create redirect={redirectUrl} transform={transform}>
      <ServiceItemForm />
    </Create>
  );
};
