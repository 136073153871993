import { Box } from '@mui/material';
import { FC } from 'react';
import {
  CreateContextProvider,
  Datagrid,
  Pagination,
  ReferenceManyField,
  TextField,
  useCreateController,
  useRecordContext,
} from 'react-admin';
import { CreateInDialogButton } from 'src/Components/CreateInDialogButton';
import { EditInDialogButton } from 'src/Components/EditInDialogButton';
import { ServiceForm } from 'src/Pages/Services/ServiceForm';

export const ServiceTab: FC = () => {
  const record = useRecordContext();
  const context = useCreateController({
    resource: 'services',
    record: { contract: { id: record?.id }, contragent: { id: record?.contragent.id } },
    mutationOptions: {},
  });

  return (
    <Box sx={{ mt: 2 }}>
      <CreateContextProvider value={context}>
        <CreateInDialogButton>
          <ServiceForm />
        </CreateInDialogButton>
      </CreateContextProvider>

      <ReferenceManyField
        reference="services"
        target="contract"
        label={false}
        pagination={<Pagination />}
      >
        <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
          <TextField source="address.name" label="Название адреса" />
          <TextField source="address.address" label="Адрес" />
          <TextField source="coast" label="Стоимость" />
          <TextField source="area" label="Площадь" />
          <EditInDialogButton>
            <ServiceForm />
          </EditInDialogButton>
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};
