import { Box, Button } from '@mui/material';
import { useState } from 'react';
import {
  Datagrid,
  EditButton,
  FunctionField,
  Pagination,
  ReferenceManyField,
  Show,
  TextField,
} from 'react-admin';
import { ServiceItemDto } from 'src/dto/ServiceItemDto';
import { CreateServiceItemDialog } from 'src/Pages/ServiceItems/CreateServiceItemDialog';
import { findServicePeriodChoice } from 'src/utils/findServicePeriodChoice';

export const ServiceShow = () => {
  const [isServiceItemDialogOpen, setIsServiceItemDialogOpen] = useState(false);

  const handleServiceItemDialogClose = () => {
    setIsServiceItemDialogOpen(false);
  };

  const handleServiceItemDialogOpen = () => {
    setIsServiceItemDialogOpen(true);
  };

  return (
    <Show>
      <Box sx={{ pt: 2, pb: 5 }}>
        <Box sx={{ ml: 2 }}>
          <Button size="small" variant="contained" onClick={handleServiceItemDialogOpen}>
            Добавить обработку
          </Button>
        </Box>
        <ReferenceManyField
          reference="service-items"
          target="service"
          label={false}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="edit" size="medium" bulkActionButtons={false}>
            <TextField source="serviceCategory.name" label="Категория" />
            <FunctionField
              render={({ period }: ServiceItemDto) => findServicePeriodChoice(period)}
              label="Периодичность"
            />
            <TextField source="count" label="Обработок" />
            <FunctionField
              render={({ serviceItemUnit, area }: ServiceItemDto) =>
                `${area ? area : ''}${serviceItemUnit?.name ? serviceItemUnit?.name : ''}`
              }
              label="Объём"
            />
            <TextField source="coast" label="Cтоимость" />
            <TextField source="totalCoast" label="общая стоимость" />
            <EditButton label="" />
          </Datagrid>
        </ReferenceManyField>
      </Box>

      {isServiceItemDialogOpen && (
        <CreateServiceItemDialog
          isOpen={isServiceItemDialogOpen}
          onClose={handleServiceItemDialogClose}
        />
      )}
    </Show>
  );
};
