import { Typography } from '@mui/material';
import { FunctionField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';
import { contragentTypesChoices } from 'src/constants/contragentTypesChoices';
import { AddressTab } from 'src/Pages/Contragents/Components/AddressTab';
import { ContractTab } from 'src/Pages/Contragents/Components/ContractTab';

export const ContragentShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Договоры">
          <ContractTab />
        </Tab>
        <Tab label="Адреса">
          <AddressTab />
        </Tab>

        <Tab label="Данные контрагента">
          <Typography variant="h5">Данные контрагента</Typography>
          <FunctionField
            source="contragentType"
            label="Вид контрагента"
            render={({ contragentType }: { contragentType: string }) =>
              contragentTypesChoices.find(({ id }) => id === contragentType)?.name || '—'
            }
          />
          <TextField source="inn" label="ИНН" />
          <TextField source="shortName" label="Короткое название" emptyText="—" />
          <TextField source="name" label="Название компании" />
          <TextField source="kpp" label="КПП" emptyText="—" />
          <TextField source="ogrn" label="ОГРН" emptyText="—" />
          <TextField source="address" label="Юридический адрес" emptyText="—" />
          <TextField source="actualAddress" label="Фактический адрес" emptyText="—" />
          <TextField source="contactName" label="Контактное лицо" emptyText="—" />
          <TextField source="tel" label="Телефон" emptyText="—" />
          <TextField source="email" label="Электронная почта" emptyText="—" />

          <Typography variant="h5">Данные Банка</Typography>
          <TextField source="bik" label="БИК" emptyText="—" />
          <TextField source="bankName" label="Название банка" emptyText="—" />
          <TextField source="paymentAccount" label="Расчётный счёт" emptyText="—" />
          <TextField source="correspondentAccount" label="Корреспондентский счёт" emptyText="—" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
