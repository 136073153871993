import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { FunctionComponent, MouseEvent } from 'react';

interface Props {
  onClick: () => void;
}

export const EditButton: FunctionComponent<Props> = ({ onClick }) => {
  const handleEditClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    onClick();
  };

  return <Button onClick={handleEditClick} startIcon={<EditIcon />} />;
};
