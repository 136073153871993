import { FC } from 'react';
import { NumberInput, ReferenceInput, required, SelectInput, SimpleForm } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { servicesPeriodChoices } from 'src/constants/servicesPeriodChoices';
import { ServiceItemDto } from 'src/dto/ServiceItemDto';
import { step } from 'src/validators/step';

interface Props {
  onSubmit?: (data: FieldValues) => void;
  record?: Partial<ServiceItemDto>;
}

export const ServiceItemForm: FC<Props> = ({ record, onSubmit }) => {
  return (
    <SimpleForm record={record} onSubmit={onSubmit}>
      <ReferenceInput
        source="serviceCategory.id"
        reference="service-categories"
        validate={required()}
        perPage={1000}
      >
        <SelectInput
          optionText="name"
          label="Категория"
          variant="outlined"
          sx={{ width: 300 }}
          validate={required()}
        />
      </ReferenceInput>
      <SelectInput
        source="period"
        variant="outlined"
        label="Периодичность"
        size="small"
        choices={servicesPeriodChoices}
        sx={{ width: 300 }}
      />
      <NumberInput
        source="count"
        variant="outlined"
        label="Количество обработок"
        size="small"
        autoComplete="off"
        defaultValue={null}
        validate={step(0.01)}
        sx={{ width: 300 }}
      />
      <ReferenceInput source="serviceItemUnit.id" reference="service-item-units">
        <SelectInput
          optionText="name"
          label="единица измерения"
          variant="outlined"
          sx={{ width: 300 }}
        />
      </ReferenceInput>
      <NumberInput
        source="area"
        variant="outlined"
        label="объём"
        size="small"
        autoComplete="off"
        defaultValue={null}
        validate={step(0.01)}
        sx={{ width: 300 }}
      />
      <NumberInput
        source="coast"
        variant="outlined"
        label="цена"
        size="small"
        autoComplete="off"
        defaultValue={null}
        validate={step(0.01)}
        sx={{ width: 300 }}
      />
      <NumberInput
        source="totalCoast"
        variant="outlined"
        label="общая стоимость"
        size="small"
        autoComplete="off"
        defaultValue={null}
        validate={step(0.01)}
        sx={{ width: 300 }}
      />
    </SimpleForm>
  );
};
