import big from 'big.js';

export const step = (step: number, message?: string) => {
  return (value?: number | string | null) => {
    if (value === undefined || value === '' || value === null) {
      return;
    }
    const result = big(value).div(step).toNumber();
    if (!Number.isInteger(result)) {
      return message || `Не правильный шаг, должно быть кратно ${step}`;
    }
  };
};
