import { FC } from 'react';
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  useRecordContext,
} from 'react-admin';
import { step } from 'src/validators/step';

export const ServiceForm: FC = () => {
  const record = useRecordContext();

  return (
    <SimpleForm>
      <ReferenceInput
        reference="addresses"
        source="address.id"
        filter={{ contragent: { id: record?.contragent?.id } }}
      >
        <AutocompleteInput
          source="address"
          optionText="address"
          variant="outlined"
          size="small"
          label="Адрес"
          sx={{ width: 300 }}
          filterToQuery={(q) => ({ name: q })}
          validate={required()}
          fullWidth
        />
      </ReferenceInput>

      <NumberInput
        source="area"
        label="Площадь м²"
        variant="outlined"
        size="small"
        autoComplete="off"
        fullWidth
        defaultValue={null}
        sx={{ width: 300 }}
        validate={step(0.01)}
      />
      <NumberInput
        source="coast"
        label="Стоимость ₽"
        variant="outlined"
        size="small"
        autoComplete="off"
        fullWidth
        defaultValue={null}
        sx={{ width: 300 }}
        validate={step(0.01)}
      />
    </SimpleForm>
  );
};
