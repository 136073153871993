import { CreateButton, Datagrid, List, TextField, TextInput, TopToolbar } from 'react-admin';
import { CreateDialog } from 'src/Components/CreateDialog';
import { EditInDialogButton } from 'src/Components/EditInDialogButton';
import { ContragentForm } from 'src/Pages/Contragents/Components/ContragentForm';

const contragentsFilters = [
  <TextInput label="Название" source="shortName" autoComplete="off" alwaysOn />,
  <TextInput label="ИНН" source="inn" autoComplete="off" alwaysOn />,
];

const ListActions = () => (
  <TopToolbar>
    <CreateButton data-test-id="create-contragent-btn" />
  </TopToolbar>
);

export const ContragentsList = () => (
  <>
    <List
      title="Контрагент"
      filters={contragentsFilters}
      actions={<ListActions />}
      data-test-id="contragent-list"
      sort={{ field: 'id', order: 'DESC' }}
      emptyWhileLoading
      hasCreate
    >
      <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
        <TextField source="shortName" label="Название" />
        <TextField source="inn" label="ИНН" />
        <TextField source="address" label="Адрес" />
        <TextField source="tel" label="Телефон" />
        <EditInDialogButton>
          <ContragentForm />
        </EditInDialogButton>
      </Datagrid>
    </List>
    <CreateDialog>
      <ContragentForm />
    </CreateDialog>
  </>
);
