import { Dialog } from '@mui/material';
import { FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { DialogContext, OpenDialogOptions } from 'src/context/DialogContext';

export const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [content, setContent] = useState<ReactNode | null>(null);
  const [open, setOpen] = useState(false);
  const [closeCallback, setCloseCallback] = useState<() => void>();

  const handleOpenDialog = useCallback(
    (content: ReactNode, { onClose }: OpenDialogOptions = {}) => {
      setContent(content);
      setOpen(true);
      setCloseCallback(() => onClose);
    },
    [],
  );

  const handleCloseDialog = useCallback(() => {
    setContent(null);
    setOpen(false);
    closeCallback?.();
    setCloseCallback(undefined);
  }, [closeCallback]);

  return (
    <DialogContext.Provider
      value={{ openDialog: handleOpenDialog, closeDialog: handleCloseDialog }}
    >
      {children}
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
        {content}
      </Dialog>
    </DialogContext.Provider>
  );
};
